import React from 'react'
import "./css/styles.css"
import image from "./assets/Working.webp";

export default function Hero() {
  return (
    <div className='hero-container flex h_full w_full wrap justify_center align_center reverse_rep' id='home'>
      <div className="w_50">
        <h1 className="title">Horizons Devs</h1>
        <p className='hero_text'>Bring your vision to life with Horizon Devs. We create custom websites that engage your audience and boost business growth. Whether you're starting fresh or enhancing your online presence, our team delivers tailored solutions that help you stand out and turn visitors into customers.</p>
        <a href="#contact" className='button'>Get In Touch!</a>
      </div>
      <div className="w_50">
        <img src={image} alt='mono' style={{width:'100%', borderRadius: '50%'}}/>
      </div>
    </div>
  )
}
