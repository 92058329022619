import React from 'react';
import Contact from './components/Contact';
import { Demos } from './components/Demos';
import Hero from './components/Hero';
import Navbar from './components/Navbar';

function App() {
  return (
    <div className="App">
      
      <Navbar/>
      <main>
        <Hero/>
        <Demos/>
        <Contact/>
      </main>
    </div>
  );
}

export default App;
