import React from 'react';
import { CardDemos } from './ui/CardDemos';
import './css/styles.css';

export const Demos = () => {
  return (
    <section className="demos-section" id="demos">
      <h2 className="demos-title">Our Project Demonstrations</h2>
      <p className="demos-description">Explore some of the projects we've worked on, showcasing our expertise in web development and design.</p>
      <div className="demos-container">
        <CardDemos embedUrl="https://www.youtube.com/embed/fZ-UySq7nV0" title="Safford Demo" />
        <CardDemos embedUrl="https://www.youtube.com/embed/G9FfPAgt82E" title="All Air Demo" />
      </div>
    </section>
  );
};
