import React from 'react';
import { MenuData } from './MenuData';
import './css/navbarstyles.css';
import { useState } from 'react';

export default function Navbar() {
  const [clicked, setClicked] = useState(false);

  const handleMenuClick = () => {
    setClicked(!clicked);
  };

  const handleItemClick = (sectionId) => {
    setClicked(false); // Close the menu when a menu item is clicked

    // Scroll to the target section smoothly without changing the URL
    const targetElement = document.getElementById(sectionId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (window.location.pathname === '/') {
      window.scrollTo(0, 0); // Scroll to the top
      window.location.reload(); // Reload the page
    } else {
      window.location.href = '/';
    }
  };

  return (
    <nav className="NavbarItems">
      <h1 className="logo">
        <a href="/" onClick={handleLogoClick}>
          <img src="/img/logo.svg" alt="Logo" style={{ width: '60px', height: '100%', objectFit: 'cover' }} />
        </a>
        <i className="fab fa-rect"></i>
      </h1>

      <div 
        onClick={handleMenuClick} 
        className={`menu-icons ${clicked ? 'open' : ''}`}
        style={{ cursor: 'pointer' }}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
        {MenuData.map((item, index) => (
          <li key={index}>
            <a 
              href="/" // This link is here for accessibility purposes only
              className={item.cName} 
              onClick={(e) => {
                e.preventDefault(); // Prevent the link from navigating
                handleItemClick(item.section); // Scroll to the section
              }}
            >
              <i className={item.icon}></i> {item.title}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}
