export const MenuData = [
    {
        title: "Home",
        section: "home",
        cName: "nav-links",
        icon: "fa-solid fa-house-user"
    },
    {
        title: "Demos",
        section: "demos",
        cName: "nav-links",
        icon: "fa-solid fa-briefcase"
    },
    {
        title: "Contact Us",
        section: "contact",
        cName: "nav-links",
        icon: "fa-solid fa-circle-info"
    }
];
