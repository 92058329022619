import React, { useEffect } from 'react';

export const CardDemos = ({ embedUrl, title }) => {
  useEffect(() => {
    const originalError = console.error;

    // Override console.error to suppress YouTube-related errors
    console.error = (message, ...args) => {
      if (typeof message === 'string' && message.toLowerCase().includes('youtube')) {
        return; // Ignore all YouTube-related errors
      }
      originalError(message, ...args);
    };

    return () => {
      console.error = originalError; // Restore original console.error on cleanup
    };
  }, []);

  return (
    <figure className='card'>
      <div className="img">
        <iframe
          src={`${embedUrl}?autoplay=1&mute=1&loop=1&playlist=${embedUrl.split('/').pop()}&controls=0&modestbranding=1&showinfo=0&disablekb=1&fs=0&rel=0&iv_load_policy=3`}
          title={title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ width: '100%', height: '100%', borderRadius: '20px', pointerEvents: 'none', objectFit: 'cover' }}
          aria-label={`Video demonstration: ${title}`}
        ></iframe>
      </div>
      <figcaption className="text">{title}</figcaption>
    </figure>
  );
};
